<template>
  <div class="coupon-con">
    <template v-if="now_status == 2">
      <NavBar title="券详情" left-arrow @click-left="goBack" v-if="isWeixin_status == false"></NavBar>
      <!-- <div class="coupon-detail"></div> -->
      <Cell
        v-if="couPonInfo.coupon.type == 1"
        :title="`${couPonInfo.coupon.title}`" 
        :label="'满' + couPonInfo.coupon.m_enough_money + '减' + couPonInfo.coupon.m_dec_money + '优惠券'">
        <Icon
          slot="right-icon"
          name="qr"
          size="24px"
        />
      </Cell>
      <Cell
        v-if="couPonInfo.coupon.type == 2"
        :title="couPonInfo.coupon.zhe_discount + '折扣优惠券'" 
        title-class="coupon-item">
      </Cell>
      <Cell
        v-if="couPonInfo.coupon.type == 3"
        :title="couPonInfo.coupon.d_face_value + '代金券'" 
        title-class="coupon-item">
      </Cell>
      <Cell
        v-if="couPonInfo.coupon.type == 4"
        :title="couPonInfo.coupon.change_is_exchange + '兑换券'" 
        title-class="coupon-item">
      </Cell>
      <Cell
        v-if="couPonInfo.coupon.type == 5"
        :title="couPonInfo.coupon.d_face_value + '储值券'" 
        title-class="coupon-item">
      </Cell>
      <!-- <Panel title="门店信息" style="margin-top: 10px">
        <Cell title="名称" label="山东省济南市...">
          <Icon
            slot="right-icon"
            name="phone"
            style="line-height: inherit;font-size:30px"
          />
        </Cell>
      </Panel> -->
      <Panel title="使用须知" style="margin-top: 10px">
        <Cell title="● 优惠券类型" title-class="coupon-item" >
          <template slot="label">
            <span>{{couPonInfo.coupon.free_type == 1?'随机':''}}{{couPonInfo.coupon.type | cpn_type}}</span>
          </template>
        </Cell>
        <Cell title="● 有效期" 
          title-class="coupon-item" 
          :label="couPonInfo.start_time + '至' + couPonInfo.end_time"
        >
        </Cell>
        <Cell title="● 可用时间" title-class="coupon-item" >
          <template slot="label">
            <span>{{couPonInfo.coupon.is_use_time_day | use_time_day}}</span>
          </template>
        </Cell>
         <Cell v-if="couPonInfo.coupon.type == 4" title="● 适用服务" title-class="coupon-item" >
          <template slot="label" v-if="couPonInfo.coupon.service_goods_status == 1">
            <span v-for="(item, index) in couPonInfo.coupon.service_goods" :key="index">{{`${item.name}、`}}</span>
          </template>
          <template slot="label" v-else>
            <span>全部服务</span>
          </template>
        </Cell>
        <Cell title="核销码" title-class="coupon-item" value="点击出示核销码" is-link @click="show_code = true"></Cell>
      </Panel>
      <Panel title="详情" v-if="couPonInfo.coupon.type == 6" style="margin-top: 10px">
        <div v-html="couPonInfo.coupon.t_term"></div>
      </Panel>
      <overlay :show="show_code" @click="show_code = false">
        <div class="hexiao-img">
          <img :src="couPonInfo.card_num_url" alt="">
        </div>
      </overlay>

      <div class="add-oil">
        <router-link to="vipShopList" replace>
          <VanButton type="primary" color="rgba(232, 32, 96, 1)" block>去加油</VanButton>
        </router-link>
      </div>

    </template>
    <template v-if="now_status == 1">
      <NavBar title="券详情" left-arrow @click-left="goBack" v-if="isWeixin_status == false"></NavBar>
      <div class="coupon-detail">
        <VanImage width="100%" height="4.26rem" fit="cover" :src="couPonInfo.share_pic" />
      </div>
      <Cell
        v-if="couPonInfo.type == 1"
        :title="couPonInfo.title" 
        :label="'满' + couPonInfo.m_enough_money + '减' + couPonInfo.m_dec_money + '优惠券'">
        <Icon
          slot="right-icon"
          name="qr"
          size="24px"
        />
      </Cell>
      <Cell v-if="couPonInfo.type == 2" 
        :label="couPonInfo.sub_title"
        :title="couPonInfo.zhe_discount + '%折扣优惠券'" 
        title-class="coupon-item">
      </Cell>
      <Cell v-if="couPonInfo.type == 3" 
        :label="couPonInfo.sub_title"
        :title="couPonInfo.d_face_value + '元代金券'" 
        title-class="coupon-item">
      </Cell>
      <Cell v-if="couPonInfo.type == 4" 
        :label="couPonInfo.sub_title"
        :title="couPonInfo.title" 
        title-class="coupon-item">
      </Cell>
      <Cell v-if="couPonInfo.type == 5" 
        :label="couPonInfo.sub_title"
        :title="couPonInfo.d_face_value + '元储值券'" 
        itle-class="coupon-item">
      </Cell>

      <Panel title="使用须知" style="margin-top: 10px">
        <Cell title="● 优惠券类型" title-class="coupon-item" >
          <template slot="label" >
            <span>{{couPonInfo.coupon.free_type == 1?'随机':''}}{{couPonInfo.coupon.type | cpn_type}}</span>
          </template>
        </Cell>
        <Cell title="● 有效期" 
          title-class="coupon-item" 
          :label="couPonInfo.term_start_time + '至' + couPonInfo.term_end_time"
        >
        </Cell>
       <Cell title="● 可用时间" title-class="coupon-item" >
          <template slot="label" >
            <span>{{couPonInfo.coupon.is_use_time_day | use_time_day}}</span>
          </template>
        </Cell>
        <Cell v-if="couPonInfo.coupon.type == 4" title="● 适用服务" title-class="coupon-item" >
          <template slot="label" v-if="couPonInfo.coupon.service_goods_status == 1">
            <span v-for="(item, index) in couPonInfo.coupon.service_goods" :key="index">{{`${item.name}、`}}</span>
          </template>
          <template slot="label" v-else>
            <span>全部服务</span>
          </template>
        </Cell>
      </Panel>
      <Panel title="发布人信息" style="margin-top: 10px">
        <Cell :title=" '地址:  ' + couPonInfo.address" title-class="coupon-item" :label="'手机号:  ' + couPonInfo.mobile">
        </Cell>
      </Panel>
      <Panel title="详情" v-if="couPonInfo.type == 6" style="margin-top: 10px">
        <div v-html="couPonInfo.t_term"></div>
      </Panel>
      <VanButton style="margin-top: 10px" type="primary" color="rgba(232, 32, 96, 1)" block @click="getTicket">领取</VanButton>      
    </template>
  </div>
</template>
<script>
import { NavBar, Panel, Cell, Icon, Image, Button,Overlay  } from "vant";
import CreditShop from "@/api/creditshop";
export default {
  components: {
    NavBar,
    Panel,
    Cell,
    Icon,
    Overlay,
    VanImage: Image,
    VanButton: Button
  },
  filters: {
    cpn_type: function(val) {
      switch (val) {
        case 1:
          return "满减优惠券"
        case 2:
          return "折扣优惠券"
        case 3:
          return "代金券"
        case 4:
          return "兑换券"
        case 5:
          return "储值券"
        case 6:
          return "通用优惠券"
        case 7:
          return "次数优惠券"
        default:
          break;
      }
    },
    use_time_day(val) {
      console.log(val)
      let arr = Object.keys(val);
      let showDay = arr.filter(item => val[item] == 1 ) // 过滤出瞒住条件的
      let showCont = ""
      for (let i = 0; i < showDay.length; i++) {
        const element = showDay[i];
        switch (element) {
          case "Monday":
            showCont = showCont + '周一、';
            break;
          case "Tuesday":
            showCont = showCont + '周二、';
            break;
          case "Wednesday":
            showCont = showCont + '周三、';
            break;
          case "Thursday":
            showCont = showCont + '周四、';
            break;
          case "Friday":
            showCont = showCont + '周五、';
            break;
          case "Saturday":
            showCont = showCont + '周六、';
            break;
          case "Sunday":
            showCont = showCont + '周日、';
            break;
        }
      }
      return showCont
    }
  },
  data() {
    return {
      show_code: false,
      active: '1',
      couPonInfo: {
        coupon: {
          is_use_time_day: {}
        }
      },
      now_status: 1
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.now_status = this.$route.query.type;
      this.getDetail();
    }, 500)
  },
  methods: {
    // 优惠券详情
    async getDetail() {
      try {
        var res = null;
        const { id, type } = this.$route.query;
        if (type == 1) {
          res = await CreditShop.getCpnCenterDetail(id);
        } else {
          res = await CreditShop.getCpnDetail(id);
        }
        if (res.code == 200) {
          this.couPonInfo = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 领取优惠券
    async getTicket() {
      try {
        const res = await CreditShop.getCompon(this.$route.query.id);
        if (res.code == 200) {
          this.$toast(res.msg);
        } else {
          throw(res.msg);
        }
      } catch (error) {
        this.$toast(error);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.coupon-con {
  width: 100%;
  height: 100vh;
  background: rgba(244, 244, 244, 1);
  .coupon-detail {
    width: 100%;
    height: 160px;
  }
  .coupon-item {
    text-indent: 4px;
  }
  .van-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hexiao-img {
    width: 80%;
    height: 300px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.add-oil {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
}
</style>
